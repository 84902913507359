import React from 'react';
import { FC } from 'react';
import { Loader } from './styled';

interface LoadingSpinnerProps {
  color?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ color }) => {
  return <Loader color={color} />;
};

export const CenteredLoadingSpinner: FC<LoadingSpinnerProps> = ({ color }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <LoadingSpinner color={color} />
    </div>
  );
};

export default LoadingSpinner;
