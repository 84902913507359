import styled from 'styled-components';
import { keyframes } from 'styled-components';

const LoadAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  `;

export const Loader = styled.div`
  display: inline-block;

  &:after {
    content: ' ';
    display: block;
    width: 10px;
    height: 10px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${({ color }) => (color ? color : 'gray')};
    border-color: ${({ color }) => (color ? color : 'gray')} transparent ${({ color }) => (color ? color : 'gray')};
    animation-name: ${LoadAnimation};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
  }
`;
