import React, { useState, useEffect } from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import { useWindowSize } from '../hooks/useWindowResize';
import { useLocation } from '@reach/router';
import Meet from '../components/Meet/Meet';

const IndexPage: React.FC<PageProps> = () => {
  const [isMounted, setIsMounted] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const keys = [...urlParams.keys()];
  const values = [...urlParams.values()];
  const params = keys.reduce((acc, key, index) => ({ ...acc, [key]: values[index] }), {}) as Record<string, string>;

  const { meetid, isbus, name, email, avatar } = params;

  const { height, width } = useWindowSize();

  const invalidMeet = !meetid || !name || !email || isbus === undefined;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      {isMounted && (
        <main style={{ height: height, width: width }}>
          {!invalidMeet && <Meet meetid={meetid} isbus={isbus === 'true'} name={name} email={email} avatar={avatar} />}
          {invalidMeet && <p>Invalid URL</p>}
        </main>
      )}
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Vet Meeting</title>;
