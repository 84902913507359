const { version } = require('../../package.json');

module.exports = {
  ENV: 'development',
  API_URL: 'https://dev-api.collarapp.uk/graphql',
  SUB_API_URL: 'wss://dev-api.collarapp.uk/graphql',
  MEET_URL: 'https://meet.collar.pet',
  MEET_SERVER_URL: 'jitsi.collarapp.uk',
  CLIENT_ID: `MEET-${version}`,
};
