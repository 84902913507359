export const BUS_USER_SETTINGS = {
  configOverwrite: {
    prejoinPageEnabled: false,
    disableSimulcast: true,
    disableDeepLinking: true,
    enableWelcomePage: false,
    enableUserRolesBasedOnToken: false,
    enableFeaturesBasedOnToken: false,
    enableNoAudioDetection: false,
    enableNoisyMicDetection: false,
    enableClosePage: false,
    enableCalendarIntegration: false,
    enableAutomaticUrlRecoding: false,
    disableRemoteMute: true,
    disableRemoteControl: true,
    disableRemoteInput: true,
    disableRemoteVideoTransfer: true,
    disableRtx: true,
    disableH264: true,
    requireDisplayName: false,
    startWithAudioMuted: true,
    startWithVideoMuted: true,
    startAudioOnly: true,
    disableInviteFunctions: true
  },
  interfaceConfigOverwrite: {
    SHOW_JITSI_WATERMARK: false,
    SHOW_WATERMARK_FOR_GUESTS: false,
    SHOW_BRAND_WATERMARK: false,
    SHOW_POWERED_BY: false,
    SHOW_DEEP_LINKING_IMAGE: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    SHOW_PROMOTIONAL_CLOSE_PAGE: false,
    SHOW_JITSI_PROMOTIONAL_CLOSE_PAGE: false,
    TOOLBAR_BUTTONS: [
      'microphone',
      'camera',
      'closedcaptions',
      'desktop',
      'fullscreen',
      'fodeviceselection',
      'hangup',
      'profile',
      // 'chat',
      'recording',
      'livestreaming',
      'etherpad',
      'sharedvideo',
      'settings',
      'raisehand',
      // 'videoquality',
      'filmstrip',
      // 'invite',
      'feedback',
      // 'stats',
      // 'shortcuts',
      'tileview',
      'videobackgroundblur',
      'download',
      // 'help',
      'mute-everyone'
      // 'e2ee'
    ],
    SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile', 'calendar'],
    VIDEO_LAYOUT_FIT: 'height',
    VIDEO_QUALITY_LABEL_DISABLED: true,
    VIDEO_QUALITY_LABEL_THRESHOLD: 720,
    MOBILE_APP_PROMO: false,
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
    DISABLE_FOCUS_INDICATOR: true,
    DISABLE_VIDEO_BACKGROUND: true,
    DISABLE_TRANSCRIPTION_SUBTITLES: true,
    DISABLE_RINGING: true,
    DISABLE_REMOTE_CONTROL: true,
    DISABLE_PRIVATE_MESSAGES: true,
    DISABLE_PRESENCE_STATUS: true,
    DISABLE_POLLING: true,
    DISABLE_P2P: true,
    DISABLE_NAME_UPDATE: true
  }
};

export const APP_USER_SETTINGS = {
  configOverwrite: {
    prejoinPageEnabled: false,
    disableSimulcast: true,
    disableDeepLinking: true,
    enableWelcomePage: false,
    enableUserRolesBasedOnToken: false,
    enableFeaturesBasedOnToken: false,
    enableNoAudioDetection: false,
    enableNoisyMicDetection: false,
    enableClosePage: false,
    enableCalendarIntegration: false,
    enableAutomaticUrlRecoding: false,
    disableRemoteMute: true,
    disableRemoteControl: true,
    disableRemoteInput: true,
    disableRemoteVideoTransfer: true,
    disableRtx: true,
    disableH264: true,
    requireDisplayName: false,
    startWithAudioMuted: true,
    startWithVideoMuted: true,
    startAudioOnly: true,
    disableInviteFunctions: true
  },
  interfaceConfigOverwrite: {
    SHOW_JITSI_WATERMARK: false,
    SHOW_WATERMARK_FOR_GUESTS: false,
    SHOW_BRAND_WATERMARK: false,
    SHOW_POWERED_BY: false,
    SHOW_DEEP_LINKING_IMAGE: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    SHOW_PROMOTIONAL_CLOSE_PAGE: false,
    SHOW_JITSI_PROMOTIONAL_CLOSE_PAGE: false,
    TOOLBAR_BUTTONS: [
      'microphone',
      'camera',
      'closedcaptions',
      'desktop',
      'fullscreen',
      'fodeviceselection',
      'hangup',
      'profile',
      // 'chat',
      'recording',
      'livestreaming',
      'etherpad',
      'sharedvideo',
      'settings',
      'raisehand',
      // 'videoquality',
      'filmstrip',
      // 'invite',
      'feedback',
      // 'stats',
      // 'shortcuts',
      'tileview',
      'videobackgroundblur',
      'download',
      // 'help',
      'mute-everyone'
      // 'e2ee'
    ],
    SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile', 'calendar'],
    VIDEO_LAYOUT_FIT: 'height',
    VIDEO_QUALITY_LABEL_DISABLED: true,
    VIDEO_QUALITY_LABEL_THRESHOLD: 720,
    MOBILE_APP_PROMO: false,
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
    DISABLE_FOCUS_INDICATOR: true,
    DISABLE_VIDEO_BACKGROUND: true,
    DISABLE_TRANSCRIPTION_SUBTITLES: true,
    DISABLE_RINGING: true,
    DISABLE_REMOTE_CONTROL: true,
    DISABLE_PRIVATE_MESSAGES: true,
    DISABLE_PRESENCE_STATUS: true,
    DISABLE_POLLING: true,
    DISABLE_P2P: true,
    DISABLE_NAME_UPDATE: true
  }
};
