import React from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { APP_USER_SETTINGS, BUS_USER_SETTINGS } from './types';
import { CenteredLoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import config from '../../utils/config';

const Meet = ({ meetid, isbus, name, email, avatar }: { meetid: string; isbus: boolean; name: string; email: string; avatar: string }) => {
  const { configOverwrite, interfaceConfigOverwrite } = isbus ? BUS_USER_SETTINGS : APP_USER_SETTINGS;

  return (
    <JitsiMeeting
      domain={config.MEET_SERVER_URL}
      roomName={meetid}
      spinner={() => <CenteredLoadingSpinner />}
      getIFrameRef={iframeRef => {
        iframeRef.style.height = `100%`;
        iframeRef.style.width = `100%`;
        const iframe = iframeRef.querySelector('iframe');
        const doc = iframe?.contentDocument;

        if (doc) {
          const head = doc.querySelector('head');
          const link = doc.createElement('link');
          link.rel = 'stylesheet';
          link.type = 'text/css';
          link.href = 'https://collarapp.uk/static/css/jitsi.css';
          head?.appendChild(link);
        }
      }}
      configOverwrite={configOverwrite}
      interfaceConfigOverwrite={interfaceConfigOverwrite}
      userInfo={{ displayName: name, email }}
      onApiReady={api => {
        api.executeCommand('avatarUrl', avatar);
        api.executeCommand('subject', 'Vet Meeting');

        api.addListener('videoConferenceLeft', () => {
          window.location.href = `${config.MEET_URL}/exit`;
        });
      }}
    />
  );
};

export default Meet;
